<template>
    <div class="profile__badges" v-if="positionName || userSportIds?.length">
        <SportTypeBadge
            v-if="positionName"
            :title="positionName"
            :orange="orange"
        />

        <template v-if="userSportIds">
            <template
                v-for="row in userSportIds.slice(0, maxSportsLabels - 1)"
                :key="row.sport_id"
            >
                <SportTypeBadge
                    v-if="sportStore.getById(row.sport_id)"
                    :title="sportStore.getById(row.sport_id).name"
                    :orange="orange"
                    :level="row.level"
                />
            </template>

            <SportTypeBadge
                v-if="userSportIds.length > maxSportsLabels"
                :orange="orange"
                :title="
                    '+' +
                    (userSportIds.length +
                        (positionName ? 1 : 0) -
                        maxSportsLabels)
                "
            />
        </template>
    </div>
</template>

<script setup>
import _ from 'lodash';
const props = defineProps({
    user: {},
    orange: { default: false },
    maxLabels: { default: 9 },
    badgesByFilterSport: {
        default: false,
    },
    visibleBadgeIds: {},
});

const maxSportsLabels = computed(() => {
    return props.maxLabels - (sportStore.getPositionByUser(props.user) ? 1 : 0);
});

const sportStore = useSportStore(),
    filterStore = useFilterStore();

const positionName = computed(() => {
    if (useHasAccountPosition(props.user)) {
        return sportStore.getPositionByUser(props.user);
    }
});

const userSportIds = computed(() => {
    const sports = _.orderBy(
        props.user.assignedSportsLevels,
        (sport) => {
            if (props.badgesByFilterSport) {
                return _.castArray(filterStore.form.sport_ids).includes(
                    sport.sport_id
                )
                    ? 1
                    : 0;
            }

            return 0;
        },
        'desc'
    );

    // Show only whitelisted sports
    let whitelistedSports = sports.filter((sport) => {
        if (props.visibleBadgeIds) {
            return props.visibleBadgeIds.includes(sport.sport_id);
        }

        return sport;
    });

    // Or show all if no whitelisted has been found
    if (whitelistedSports.length == 0) {
        return sports;
    }

    return whitelistedSports;
});
</script>

<style lang="scss">
.profile__badges {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem 0.8rem;
    margin-top: 1rem;

    @include more-than(md) {
        margin-top: 0;

        & > * {
            align-self: flex-start;
        }
    }
}
</style>
